import React, { ReactElement } from 'react'

import Layout from '../components/layout'

import { ClinicSandnesDetailed } from '@/components/clinic-sandnes'
import { ClinicStavangerDetailed } from '@/components/clinic-stavanger'

// markup
const IndexPage = (): ReactElement => {
  return (
    <Layout>
      <h1>Våre klinikker</h1>
      <div className="grid grid-cols-1 gap-8">
        <ClinicSandnesDetailed />
        <ClinicStavangerDetailed />
      </div>
    </Layout>
  )
}

export default IndexPage
